import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import _ from 'lodash';

import StudyResultDialog from '@/user/components/study/dialog/result/StudyResultDialog.vue';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import StudyMixin from '@/user/mixins/StudyMixin';
import WindowAdjustMixin from '@/user/mixins/WindowAdjustMixin';

import getQuestion from '@/master/coloring';

/** 左右 */
enum QuestionLR {
  l = 'l', // 左
  r = 'r', // 右
};

/** 縦横その他 */
enum QuestionVHO {
  v = 'v', // 縦
  h = 'h', // 横
  o = 'o', // その他
};

/** 倍数 */
enum QuestionMultiple {
  m2 = 2,
  m3 = 3,
  m5 = 5,
};

/** 色 */
enum QuestionColor {
  r = 'r', // 赤
  g = 'g', // 緑
  b = 'b', // 青
  y = 'y', // 黄
};

/** 選択ロジック情報 */
type SelectionLogic = {
  vho: QuestionVHO;
  multiple: QuestionMultiple | null;
};

/** 選択色情報 */
type SelectionColor = {
  color: QuestionColor;
};

/** ぬりえ選択情報 */
type ColoringSelection = {
  lr: QuestionLR;
  logic: SelectionLogic;
  color: SelectionColor | null;
};

/** ぬりえセル情報 */
type ColoringCell = {
  x: number;
  y: number;
  color: QuestionColor | null;
};

/** 位置情報 */
type Coordinate = {
  x: number;
  y: number;
};

/** 回答欄位置情報 */
type ReplyBoxCoordinate = {
  start: Coordinate;
  end: Coordinate;
};

/** ロジック回答欄 */
type LogicReplyBox = {
  lr: QuestionLR | null;
  item: DragItem | null;
  coordinate: ReplyBoxCoordinate;
  disabled: boolean;
};

/** 色回答欄 */
type ColorReplyBox = {
  item: DragItem | null;
  coordinate: ReplyBoxCoordinate;
  disabled: boolean;
};

/** ドラッグアイテム */
type DragItem = {
  selection: SelectionLogic | SelectionColor;
  initialX: number;
  initialY: number;
  x: number;
  y: number;
  isDragging: boolean;
  imgName: string;
};

/** ドラッグアイテムサイズ */
type DragItemSize = {
  width: number;
  height: number;
};

/** ドラッグアイテムサイズグループ */
type DragItemSizeGroup = {
  logic: DragItemSize;
  color: DragItemSize;
};

/** 入力補助 */
type Assistance = {
  lr?: QuestionLR;
  item: DragItem;
  coordinate: ReplyBoxCoordinate | null;
};

/** チュートリアルの回答情報 */
const TUTORIAL_REPLIES = [
  {
    sectionNo: 1,
    unitNo: 1,
    replyRows: [
      {
        lr: QuestionLR.l,
        multiple: QuestionMultiple.m2,
        color: QuestionColor.r
      },
    ]
  },
  {
    sectionNo: 1,
    unitNo: 4,
    replyRows: [
      {
        lr: QuestionLR.l,
        multiple: QuestionMultiple.m2,
        color: QuestionColor.r
      },
      {
        lr: QuestionLR.l,
        multiple: QuestionMultiple.m3,
        color: QuestionColor.b
      },
    ]
  },
  {
    sectionNo: 2,
    unitNo: 1,
    replyRows: [
      {
        lr: QuestionLR.l,
        multiple: QuestionMultiple.m2,
        color: QuestionColor.r
      },
      {
        lr: QuestionLR.r,
        multiple: QuestionMultiple.m3,
        color: QuestionColor.b
      },
    ]
  },
  {
    sectionNo: 3,
    unitNo: 1,
    replyRows: [
      {
        lr: QuestionLR.l,
        multiple: QuestionMultiple.m2,
        color: QuestionColor.r
      },
      {
        lr: QuestionLR.l,
        multiple: null,
        color: QuestionColor.b
      },
    ]
  },
  {
    sectionNo: 4,
    unitNo: 1,
    replyRows: [
      {
        lr: QuestionLR.l,
        multiple: QuestionMultiple.m2,
        color: null
      },
      {
        lr: QuestionLR.l,
        multiple: null,
        color: QuestionColor.b
      },
    ]
  },
] as const;

export default mixins(RoutesMixin, StudyMixin, WindowAdjustMixin).extend({
  name: 'Coloring',

  components: {
    VueDraggableResizable,
    StudyResultDialog,
  },

  data(): {
    readonly cellCountPerRow: number;
    coloring: {
      questions: ColoringCell[];
      replies: ColoringCell[];
    },
    replyBoxes: {
      logic: LogicReplyBox;
      color: ColorReplyBox;
    }[];
    dragItem: {
      logics: DragItem[];
      colors: DragItem[];
    };
  } {
    return {
      cellCountPerRow: 10,
      coloring: {
        questions: [],
        replies: [],
      },
      replyBoxes: [],
      dragItem: {
        logics: [],
        colors: [],
      },
    };
  },

  computed: {
    hasVertical(): boolean {
      return this.sectionNo > 4;
    },
    cellCount(): number {
      return this.hasVertical ? 100 : 10;
    },
    replyRowCount(): number {
      return this.hasVertical ? 6 : 3;
    },
    bgStyle(): object {
      const direction = this.isPortrait ? 'v' : 'h';
      return {
        'background-image': `url(${require(`@/assets/img/study/coloring/coloring_bg_${direction}_${this.cellCount}.svg`)})`
      };
    },
    dragItemCount(): number {
      return this.dragItem.logics.length + this.dragItem.colors.length;
    },
    dragItemSize(): DragItemSizeGroup {
      return {
        logic: {
          width: this.calculateRatio(94),
          height: this.calculateRatio(29)
        },
        color: {
          width: this.calculateRatio(24),
          height: this.calculateRatio(24)
        }
      };
    },
    assistance(): Assistance | null {
      const tutorial = TUTORIAL_REPLIES.find(data => data.sectionNo === this.sectionNo && data.unitNo === this.unitNo);
      if (tutorial === undefined) return null;

      // 回答欄とチュートリアルの回答を比較
      for (let i = 0; i < tutorial.replyRows.length; i++) {
        const replyBox    = this.replyBoxes[i];
        const tutorialRow = tutorial.replyRows[i];

        // 左右と倍数が一致しない場合は倍数の入力補助情報を返却
        const lr             = tutorialRow.lr;
        const multiple       = tutorialRow.multiple;
        const selectionLogic = replyBox.logic.item?.selection;
        if (selectionLogic === undefined || ('multiple' in selectionLogic && (selectionLogic.multiple !== multiple || replyBox.logic.lr !== lr))) {
          const item = this.dragItem.logics.find(l => (l.selection as SelectionLogic).multiple === multiple);
          if (item === undefined) return null;
          return {
            lr,
            item,
            coordinate: replyBox.logic.coordinate
          };
        }

        // 色が一致しない場合は色の入力補助情報を返却
        const color          = tutorialRow.color;
        const selectionColor = replyBox.color.item?.selection;
        if (color !== null && (selectionColor === undefined || ('color' in selectionColor && selectionColor.color !== color))) {
          const item = this.dragItem.colors.find(l => (l.selection as SelectionColor).color === color);
          if (item === undefined) return null;
          return {
            item,
            coordinate: replyBox.color.coordinate
          };
        }
      }

      return null;
    },
    assistanceStyle(): Record<string, string> {
      if (this.assistance === null) return {};
      const coordinate = this.assistance.coordinate;
      const dragItem   = this.assistance.item;
      if (coordinate === null) return {};

      const isLogic  = 'multiple' in dragItem.selection;
      const itemSize = isLogic ? this.dragItemSize.logic : this.dragItemSize.color;
      let   addDstX  = 0;
      if (isLogic) addDstX = (this.assistance.lr === QuestionLR.l ? -1 : 1) * itemSize.width / 3;
      const srcX  = dragItem.initialX + (itemSize.width / 2);
      const srcY  = dragItem.initialY + (itemSize.height / 2);
      const dstX  = (coordinate.start.x + coordinate.end.x) / 2 + addDstX;
      const dstY  = (coordinate.start.y + coordinate.end.y) / 2;
      const width = Math.sqrt(Math.pow(srcX - dstX, 2) + Math.pow(srcY - dstY, 2));
      const deg   = Math.atan2(dstY - srcY, dstX - srcX) / (Math.PI / 180);
      return {
        '-webkit-transform': `rotate(${deg}deg)`,
        transform: `rotate(${deg}deg)`,
        top: `${srcY}px`,
        left: `${srcX}px`,
        width: `${width}px`,
      };
    },
  },

  created(): void {
    this.initializeStudyVariable();
    this.initializeStudyRequest();
    this.$_initializeQuestion();
  },

  mounted(): void {
    this.onChangeWindowSizeHandler();
  },

  methods: {
    getColoringTable(cells: ColoringCell[]): ColoringCell[][] {
      return _.chunk(cells, this.cellCountPerRow);
    },
    onDragging(item: DragItem): void {
      if (item.isDragging) return;
      item.isDragging = true;
    },
    async onDragstop(item: DragItem, left: number, top: number, isLogic: boolean): Promise<void> {
      // ドラッグ状態を戻す
      item.isDragging = false;

      // 元の位置を保持
      const oldX = item.x;
      const oldY = item.y;

      // xyの値が変更されないと位置調整しても位置が更新されないので
      // 一度ドラッグ位置に配置して明示的に更新をかける
      item.x = left;
      item.y = top;
      await Vue.nextTick();

      // 自身が回答にある場合は一度取り除く
      const prop = this.$_getDragItemPropName(isLogic);
      const own  = this.replyBoxes.find(rb => rb[prop].item === item);
      if (own !== undefined) this.$_resetBoxItem(own[prop]);

      // 配置位置チェック
      let isHit = false;
      let setLogicItemIndex = -1;
      const itemWidth  = this.dragItemSize[prop].width;
      const itemHeight = this.dragItemSize[prop].height;
      const x          = left + itemWidth / 2;
      const y          = top + itemHeight / 2;
      for (let i = 0; i < this.replyBoxes.length; i++) {
        const box    = this.replyBoxes[i][prop];
        const startX = box.coordinate.start.x;
        const startY = box.coordinate.start.y;
        const endX   = box.coordinate.end.x;
        const endY   = box.coordinate.end.y;
        // 位置が一致した場合
        isHit = startX < x && x < endX && startY < y && y < endY;
        if (isHit) {
          // 無効の回答欄の場合はアイテムを元の位置に戻してreturn
          if (box.disabled) {
            this.onDragstop(item, oldX, oldY, isLogic);
            return;
          }

          // ロジック入力の場合は左右の配置と禁則チェック
          let lr: QuestionLR | null = null;
          if (isLogic) {
            // 左右の配置位置チェック（1行目は必ず左）
            const isLeft = i === 0 ? true : x < (startX + endX) / 2;
            lr = isLeft ? QuestionLR.l : QuestionLR.r;

            // 禁則チェックで不可となった場合はアイテムを元の位置に戻してreturn
            if (this.$_isProhibitedForLogic(item, i, lr)) {
              this.onDragstop(item, oldX, oldY, isLogic);
              return;
            }

            // ロジック入力位置を保持
            setLogicItemIndex = i;
          }

          // すでに回答が入っている場合は外しておく
          this.$_resetBoxItem(box);

          // 回答格納と位置調整してbreak
          if (isLogic && 'lr' in box) box.lr = lr;
          box.item = item;
          this.$_adjustReplyDragItem(item, box);
          break;
        }
      }

      // どの回答欄にも入らない場合は元の位置に戻す
      if (!isHit) this.$_resetItemPosition(item);

      // ロジック入力位置、または空のロジック以降に回答が入っていたら取り除く
      let isReset = false;
      this.replyBoxes.forEach((rb, i) => {
        if (isReset) {
          this.$_resetBoxItem(rb.logic);
          this.$_resetBoxItem(rb.color);
          return;
        }

        const isEmptyLogic = rb.logic.item === null;
        if (isEmptyLogic) this.$_resetBoxItem(rb.color);
        isReset = i === setLogicItemIndex || isEmptyLogic;
      });

      // 回答側のぬりえ描画
      const selections: ColoringSelection[] = [];
      for (const rb of this.replyBoxes) {
        const logicBox = rb.logic;
        const colorBox = rb.color;
        if (logicBox.lr === null || logicBox.item === null) break;

        selections.push({
          lr:    logicBox.lr,
          logic: logicBox.item.selection as SelectionLogic,
          color: colorBox.item !== null ? colorBox.item.selection as SelectionColor : null,
        });
      }
      this.$_paint(selections, false);

      // 正誤判定
      this.$_judge();
    },
    // WindowAdjustMixin上書き
    onChangeWindowSizeHandler(): void {
      this.$_calcReplyBoxCoordinate();
      this.$_calcItemLayout();
      // vue-draggable-resizableのparentサイズが変わらないので明示的にイベント通知
      window.dispatchEvent(new Event('resize'));
    },
    $_initializeQuestion(): void {
      // 問題取得、成形
      const question  = getQuestion(this.sectionNo, this.unitNo);
      const questions = question.question.split(',');
      const selections: ColoringSelection[] = [];
      for (const q of questions) {
        const chars = q.split('') as [
          keyof typeof QuestionLR,
          keyof typeof QuestionVHO,
          keyof typeof QuestionMultiple | ' ',
          keyof typeof QuestionColor | ' ',
        ];
        selections.push({
          lr: QuestionLR[chars[0]],
          logic: {
            vho: QuestionVHO[chars[1]],
            multiple: chars[2] !== ' ' ? Number(QuestionMultiple[QuestionMultiple[chars[2]]]) : null,
          },
          color: chars[3] !== ' ' ? { color: QuestionColor[chars[3]] } : null,
        });
      }

      // ぬりえ
      this.coloring = {
        questions: [],
        replies: [],
      };
      for (let i = 0; i < this.cellCount; i++) {
        const x     = i % this.cellCountPerRow + 1;
        const y     = Math.floor(i / this.cellCountPerRow) + 1;
        const color = null;
        this.coloring.questions.push({x, y, color});
        this.coloring.replies.push({x, y, color});
      }

      // 回答欄
      this.replyBoxes = [];
      const newCoordinate = (): ReplyBoxCoordinate => ({
        start: {
          x: 0,
          y: 0,
        },
        end: {
          x: 0,
          y: 0,
        },
      });
      for (let i = 0; i < this.replyRowCount; i++) {
        // 問題の回答数より後の回答欄は無効
        const disabled = questions.length - 1 < i;

        this.replyBoxes.push({
          logic: {
            lr: null,
            item: null,
            coordinate: newCoordinate(),
            disabled,
          },
          color: {
            item: null,
            coordinate: newCoordinate(),
            disabled,
          }
        });
      }

      // ドラッグアイテム追加用関数
      const pushItemOfReplyRowCount = (items: DragItem[], selection: SelectionLogic | SelectionColor, imgName: string): void => {
        for (let i = 0; i < this.replyRowCount; i++) {
          items.push({
            selection,
            imgName,
            initialX: 0,
            initialY: 0,
            x: 0,
            y: 0,
            isDragging: false,
          });
        }
      };

      // ロジックドラッグアイテム
      this.dragItem.logics = [];
      Object.values(QuestionVHO).forEach(vho => {
        // 縦方向がない場合は縦を飛ばす
        if (!this.hasVertical && vho === QuestionVHO.v) return;

        // 縦横その他の各アイテムを追加
        switch (vho) {
          case QuestionVHO.v:
          case QuestionVHO.h:
            const baseImgName = this.hasVertical ? `coloring_m_${vho}` : 'coloring_m';
            Object.values(QuestionMultiple).forEach(multiple => {
              if (typeof multiple === 'string') return; // number enumの場合、keyもループ対象になるので除外する
              const item: SelectionLogic = {
                vho,
                multiple,
              };
              pushItemOfReplyRowCount(this.dragItem.logics, item, `${baseImgName}_${multiple}`);
            });
            break;
          case QuestionVHO.o:
            const item: SelectionLogic = {
              vho,
              multiple: null,
            };
            pushItemOfReplyRowCount(this.dragItem.logics, item, 'coloring_m_o');
            break;
        }
      });

      // 色ドラッグアイテム
      this.dragItem.colors = [];
      Object.values(QuestionColor).forEach(color => {
        const item: SelectionColor = { color };
        pushItemOfReplyRowCount(this.dragItem.colors, item, `coloring_c_${color}`);
      });

      // ぬりえ描画
      this.$_paint(selections, true);
    },
    $_isProhibitedForLogic(item: DragItem, index: number, lr: QuestionLR): boolean {
      const selection = item.selection as SelectionLogic;
      // 1行目
      if (index === 0) {
        // 選択肢「それ以外」は不可
        if (selection.vho === QuestionVHO.o) return true;

      // 2行目以降
      } else if (index > 0) {
        // 前行情報
        const prevBox  = this.replyBoxes[index - 1].logic;
        const prevItem = prevBox.item;

        // 前行が空、または自分自身は不可
        if (prevItem === null || item === prevItem) return true;
        const prevLogic = prevItem.selection as SelectionLogic;
        // 選択肢「それ以外」
        if (selection.vho === QuestionVHO.o) {
          // 右の初行（＝前行が左）は不可
          if (lr === QuestionLR.r && prevBox.lr === QuestionLR.l) return true;
          // 前行が左右同一の「それ以外」は不可
          if (prevLogic.vho === QuestionVHO.o && lr === prevBox.lr) return true;
        }
      }

      return false;
    },
    $_getDragItemPropName(isLogic: boolean): 'logic' | 'color' {
      return isLogic ? 'logic' : 'color';
    },
    $_resetItemPosition(item: DragItem | null): void {
      if (item === null) return;
      item.x = item.initialX;
      item.y = item.initialY;
    },
    $_resetBoxItem(box: LogicReplyBox | ColorReplyBox): void {
      this.$_resetItemPosition(box.item);
      box.item = null;
      if ('lr' in box) box.lr = null;
    },
    $_judge(): void {
      // 正誤判定（問題と回答のぬりえが同じ配色であれば正解）
      const qColors   = this.coloring.questions.map(c => c.color);
      const rColors   = this.coloring.replies.map(c => c.color);
      const isCorrect = JSON.stringify(qColors) === JSON.stringify(rColors);

      // 正解の場合は正誤判定結果表示
      if (isCorrect) this.judgeScoreResult(isCorrect);
    },
    $_paint(selections: ColoringSelection[], isQuestion: boolean): void {
      // 回答の場合は一度色情報を空にする
      if (!isQuestion) {
        this.coloring.replies.forEach(c => c.color = null);
      }

      // ぬりえ処理
      const coloringCells = isQuestion ? this.coloring.questions : this.coloring.replies;
      const prevSelection: {
        left: {
          logic: SelectionLogic | null;
          filteredCells: ColoringCell[];
        };
        right: {
          logic: SelectionLogic | null;
        };
      } = {
        left: {
          logic: null,
          filteredCells: [],
        },
        right: {
          logic: null,
        },
      };
      for (const selection of selections) {
        // 対象セルを絞り込む
        const isLeft         = selection.lr === QuestionLR.l;
        const cells          = isLeft ? coloringCells : prevSelection.left.filteredCells;
        const selectionLogic = selection.logic;
        let isDeny   = false;
        let vho      = selectionLogic.vho;
        let multiple = selectionLogic.multiple ?? 1;
        if (vho === QuestionVHO.o) {
          const prevLogic = isLeft ? prevSelection.left.logic : prevSelection.right.logic;
          if (prevLogic === null) throw new Error('illegal setting "other"');
          isDeny   = true;
          vho      = prevLogic.vho;
          multiple = prevLogic.multiple ?? 1;
        }
        const propXY = vho === QuestionVHO.v ? 'y' : 'x';
        const filteredCells = cells.filter((cell) => (cell[propXY] % multiple === 0) !== isDeny);

        // 色情報がある場合は色を設定
        const selectionColor = selection.color;
        if (selectionColor !== null) filteredCells.forEach(cell => cell.color = selectionColor.color);

        // 以降の行の条件として情報を保持しておく
        if (isLeft) {
          prevSelection.left.logic         = selectionLogic;
          prevSelection.left.filteredCells = filteredCells;
          prevSelection.right.logic        = null;
        } else {
          prevSelection.right.logic = selectionLogic;
        }
      }
    },
    $_calcReplyBoxCoordinate(): void {
      // 座標設定関数
      const setCoordinate = (isLogic: boolean): void => {
        const elms = this.$refs[isLogic ? 'replyLogicBoxes' : 'replyColorBoxes'] as HTMLElement[];
        if (elms === undefined || elms.length === 0) return;

        let i = 0;
        this.replyBoxes.forEach(row => {
          const elm    = elms[i];
          const parent = elm.offsetParent as HTMLElement;
          if (elm === undefined) return;

          const prop       = this.$_getDragItemPropName(isLogic);
          const itemWidth  = this.dragItemSize[prop].width;
          const itemHeight = this.dragItemSize[prop].height;
          const startX     = parent.offsetLeft + elm.offsetLeft;
          const startY     = parent.offsetTop + elm.offsetTop;
          row[prop].coordinate = {
            start: {
              x: startX,
              y: startY,
            },
            end: {
              x: startX + (isLogic ? this.calculateRatio(126) : itemWidth),
              y: startY + itemHeight,
            },
          };
          i++;
        });
      };

      // 座標設定
      setCoordinate(true);
      setCoordinate(false);
    },
    $_calcItemLayout(): void {
      const calculate = (isLogic: boolean): void => {
        const prop       = this.$_getDragItemPropName(isLogic);
        const itemWidth  = this.dragItemSize[prop].width;
        const itemHeight = this.dragItemSize[prop].height;
        let items: DragItem[];
        let boxElm: HTMLElement;
        let x: number;
        let y: number;
        if (isLogic) {
          items  = this.dragItem.logics;
          boxElm = this.$refs.logicBox as HTMLElement;
          x      = boxElm.offsetLeft + this.calculateRatio(18);
          y      = boxElm.offsetTop + this.calculateRatio(this.hasVertical ? 10 : 60);
        } else {
          items  = this.dragItem.colors;
          boxElm = this.$refs.colorBox as HTMLElement;
          x      = boxElm.offsetLeft + this.calculateRatio(8);
          y      = boxElm.offsetTop + this.calculateRatio(8);
        }

        let prevItemName = items[0].imgName;
        items.forEach(item => {
          // 画像が変わったら次の配置位置に調整
          if (prevItemName !== item.imgName) {
            if (isLogic) y += itemHeight + this.calculateRatio(6);
            else         x += itemWidth + this.calculateRatio(6);
            prevItemName = item.imgName;
          }

          // アイテムの位置設定
          item.initialX = x;
          item.initialY = y;
          item.x = x;
          item.y = y;

          // 回答に入っている場合は位置調整
          this.replyBoxes.forEach(rb => {
            const box = rb[prop];
            if (box.item === item) {
              this.$_adjustReplyDragItem(item, box);
              return;
            }
          });
        });
      };

      // 計算
      calculate(true);
      calculate(false);
    },
    $_adjustReplyDragItem(item: DragItem, box: LogicReplyBox | ColorReplyBox): void {
      const prop       = this.$_getDragItemPropName('lr' in box);
      const itemWidth  = this.dragItemSize[prop].width;
      const itemHeight = this.dragItemSize[prop].height;
      const startX     = box.coordinate.start.x;
      const startY     = box.coordinate.start.y;
      const endX       = box.coordinate.end.x;
      const endY       = box.coordinate.end.y;
      if ('lr' in box) {
        item.x = box.lr === QuestionLR.l ? startX : endX - itemWidth;
        item.y = startY;
      } else {
        item.x = (startX + endX - (itemWidth - this.calculateRatio(5))) / 2;
        item.y = (startY + endY - (itemHeight - this.calculateRatio(5))) / 2;
      }
    },
  },
});
