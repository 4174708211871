import TypeStrictData, { fieldType } from '@/classes/common/TypeStrictData';

import { getQuestionData, parseTsv } from '@/master';
import question from '@/master/coloring/question.txt';

/** 問題マスタクラス */
export class QuestionData extends TypeStrictData {
  /** 問題No */
  @fieldType(Number)
  public questionNo = -1;

  /** 問題 */
  @fieldType(String)
  public question = '';
}

/** 問題マスタデータ一覧 */
const questionListData = parseTsv(QuestionData, question);

/** 問題取得 */
const getQuestion = (sectionNo: number, unitNo: number): QuestionData => {
  return getQuestionData(questionListData, sectionNo, unitNo);
};

export default getQuestion;
